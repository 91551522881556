<template lang="pug">
include ../../../../configs/template
form.row.align-end
  div.col-12
    +select-validation('body.additional_info.passport_type', 'passportTypes', 'civilPassport.type', 'nameLang', '["required"]')(:readonly="isCheckDocument")

  div(v-if="isCheckDocument").col-12.d-flex.flex-wrap.pa-0
    div.col-sm-12.col-md-6.col-xl-4
      +field-validation('body.additional_info.last_name_ukr', 'lastNameUK', '["alphaUA", "required"]')
    div.col-sm-12.col-md-6.col-xl-4
      +field-validation('body.additional_info.first_name_ukr', 'firstNameUK', '["alphaUA", "required"]')
    div.col-sm-12.col-md-6.col-xl-4
      +field('body.additional_info.middle_name_ukr', '"middleNameUK"')
    div(v-if="body.additional_info.passport_type === 2").col-sm-12.col-md-6
      +field-validation('body.additional_info.last_name_eng', 'lastNameEN', '["alphaEN", "required"]')
    div(v-if="body.additional_info.passport_type === 2").col-sm-12.col-md-6
      +field-validation('body.additional_info.first_name_eng', 'firstNameEN', '["alphaEN", "required"]')

  div(v-if="isCheckDocument").col-12
    +select-validation('body.additional_info.country', 'listCountries', 'citizenship', 'valueLang', '["required"]')
  div.col-sm-12.col-md-6.col-xl-4
    +field-validation('body.additional_info.serial', 'serialAndNum', '["required"]')(:readonly="isCheckDocument")
  div.col-sm-12.col-md-6.col-xl-4
    +date-picker-validation('body.additional_info.date_start', 'dateIssue', 'dateStartObject', '["required", "minValue", "maxValue"]', 'isCheckDocument', '!isCheckDocument')(
      :max="new Date().toISOString()" min="1900-01-01"
      :readonly="isCheckDocument")
  div(v-if="body.additional_info.passport_type === 2").col-sm-12.col-md-6.col-xl-4
    +date-picker-validation('body.additional_info.date_end', 'dateTermination', 'dateEndObject', '["required", "minValue"]', 'isCheckDocument', '!isCheckDocument')(
      :min="body.additional_info.date_start"
      :readonly="isCheckDocument")
  div(v-if="isCheckDocument").col-12.d-flex.flex-wrap.pa-0.align-end
    div.col-sm-12.col-md-6
      +field('body.additional_info.issued_by', '"passportIssued"')

    b.col-12.my-3.pl-3 {{ $t('registrationDoc') }}:

    div.col-sm-12.col-md-6.col-xl-3
      +select('body.additional_info.city_registration.city.country', 'listCountries', 'country', 'valueLang')(
        @input="(item) => getRegionsList(item, 'city_registration')")
    div.col-sm-12.col-md-6.col-xl-3
      +select('body.additional_info.city_registration.city.region', 'registrationRegionsList', 'region', 'valueLang')(
        :loading="isRegionLoading" @input="(item) => getCitiesList(item, 'city_registration')")
    div.col-md-12.col-xl-6
      +select('body.additional_info.city_registration.city.city', 'registrationCitiesList', 'city', 'valueLang')(
        :loading="isCityLoading")
    div.col-sm-6.col-md-2
      +field-validation('body.additional_info.city_registration.index', 'placeIndex', '["maxLength", "minLength"]')
    div.col-sm-12.col-md-6
      +field('body.additional_info.city_registration.street', '"street"')
    div.col-sm-6.col-md-2
      +field('body.additional_info.city_registration.house', '"house"')
    div.col-sm-6.col-md-2
      +field('body.additional_info.city_registration.flat', '"flat"')

    v-checkbox(v-model="sameResidentPlace" :label="sameResidentPlace ? $t('sameRegistration') : $t('notSameRegistration')").col-12

    div(v-if="!sameResidentPlace").d-flex.flex-wrap
      b.col-12.my-3.pl-3 {{ $t('residentPlace') }}:
      div.col-sm-12.col-md-6.col-xl-3
        +select('body.additional_info.resident.city.country', 'listCountries', 'country', 'valueLang')(
          @input="(item) => getRegionsList(item, 'resident')")
      div.col-sm-12.col-md-6.col-xl-3
        +select('body.additional_info.resident.city.region', 'residentRegionsList', 'region', 'valueLang')(
          :loading="isRegionLoading" @input="(item) => getCitiesList(item, 'resident')")
      div.col-md-12.col-xl-6
        +select('body.additional_info.resident.city.city', 'residentCitiesList', 'city', 'valueLang')(
          :loading="isCityLoading")
      div.col-sm-6.col-md-2
        +field-validation('body.additional_info.resident.index', 'placeIndex', '["maxLength", "minLength"]')
      div.col-sm-12.col-md-6
        +field('body.additional_info.resident.street', '"street"')
      div.col-sm-6.col-md-2
        +field('body.additional_info.resident.house', '"house"')
      div.col-sm-6.col-md-2
        +field('body.additional_info.resident.flat', '"flat"')
    FileDropZone(ref="mediaContent").col-12
  div.col-12
    v-btn(v-if="isCheckDocument" color="success" @click="saveDocument" :loading="isLoadingSave").mr-4 {{$t('save')}}
    v-btn(v-else color="success" @click="checkDocument" :loading="isLoading") {{$t('btn.checkDocument')}}
    v-btn(v-if="isCheckDocument" color="secondary" @click="clearForm") {{$t('btn.clear')}}
</template>

<script>
import { verificationSC } from '@/mixins/validationRules'
import { mapActions, mapState } from 'vuex'
import { clearBody } from '@/mixins/main'

const initBody = () => (
  {
    additional_info: {
      passport_type: null,
      last_name_ukr: null,
      first_name_ukr: null,
      middle_name_ukr: null,
      last_name_eng: null,
      first_name_eng: null,
      country: null,
      serial: null,
      date_start: null,
      date_end: null,
      issued_by: null,
      city_registration: {
        city: {
          country: '',
          region: null,
          city: null
        },
        index: null,
        street: null,
        house: null,
        flat: null
      },
      resident: {
        city: {
          country: '',
          region: null,
          city: null
        },
        index: null,
        street: null,
        house: null,
        flat: null
      }
    }
  }
)
export default {
  name: 'CitizenPassport-digitization',
  components: {
    FileDropZone: () => import('@/components/atoms/DropZone/DropZone.vue')
  },
  data () {
    return {
      body: initBody(),
      registrationRegionsList: [],
      registrationCitiesList: [],
      residentRegionsList: [],
      residentCitiesList: [],
      sameResidentPlace: true,
      isRegionLoading: false,
      isCityLoading: false,
      isLoading: false,
      isTaxNumber: false,
      isCheckDocument: false
    }
  },
  validations () { return verificationSC.citizenPassport(this) },
  computed: {
    ...mapState({
      lang: state => state.main.lang,
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      valueLang: state => state.main.lang === 'en' ? 'value_eng' : 'value',
      passportTypes: state => state.directory.passportTypes,
      listCountries: state => state.directory.country,
      isLoadingSave: state => state.documents.isLoading
    }),
    dateStartObject () {
      return this.body.additional_info.date_start ? new Date(this.body.additional_info.date_start) : null
    },
    dateEndObject () {
      return this.body.additional_info.date_end ? new Date(this.body.additional_info.date_end) : null
    }
  },
  methods: {
    ...mapActions(['getInfoAboutDocumentForDigitizationSC', 'getRegion', 'getCity']),
    saveDocument () {
      const { statementId } = this.$route.params
      if (this.$v.$invalid) return this.$v.$touch()
      let data = {
        body: { ...this.body },
        media: {
          name: 'passport',
          files: [...this.$refs.mediaContent.filesArray]
        }
      }
      data.body.additional_info = { ...clearBody({ ...this.body.additional_info }) }
      if (this.body.additional_info.city_registration?.city?.city) {
        data.body.additional_info.city_registration.city = this.body.additional_info.city_registration.city.city
      }
      if (this.sameResidentPlace) data.body.additional_info.resident = data.body.additional_info.city_registration
      else {
        data.body.additional_info.resident.city = this.body.additional_info.resident.city.city
      }
      this.$emit('saveDocument', { id: statementId, data, method: 'setDigitizationDocumentSC' })
    },
    async checkDocument () {
      if (this.$v.$invalid) return this.$v.$touch()
      this.isLoading = true
      const { statementId } = this.$route.params
      const response = await this.getInfoAboutDocumentForDigitizationSC({ id: statementId, body: { additional_info: { ...clearBody({ ...this.body.additional_info }) } } })
      if (response) {
        this.$notification.success('notify.success.checkDocument')
        this.isCheckDocument = true
      }
      this.isLoading = false
    },
    clearForm () {
      this.isCheckDocument = false
      this.body = initBody()
      this.$v.$reset()
    },
    getRegionsList (country, type) {
      if (country) {
        this.isRegionLoading = true
        this.getRegion(country).then(response => {
          if (type === 'city_registration') this.registrationRegionsList = response.data
          else this.residentRegionsList = response.data
        }).finally(() => {
          this.isRegionLoading = false
        })
      }
    },

    getCitiesList (region, type) {
      if (region) {
        this.isCityLoading = true
        this.getCity(region).then(response => {
          if (type === 'city_registration') this.registrationCitiesList = response.data
          else this.residentCitiesList = response.data
        }).finally(() => {
          this.isCityLoading = false
        })
      }
    }
  }
}
</script>
